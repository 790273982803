import Translate from "./Translate";
import { useState } from "react";
import loadingSpinner from "./../images/loadingSpinner.gif";

function RoomCard(props) {
  const {
    nextStep,
    roomData,
    currentLanguage,
    setCurrentRoom,
    inCurrentCurrency,
    arrivalDate,
    departureDate,
  } = props;
  const nObj = { en: 0, fr: 1, es: 2, it: 3, de: 4 };
  /*
    In order to store description in different
    langs in the DB, we had the idea  of storing
    all of them split  by a ';' in a specific
    order. English is first, then French...
    And in the front end, the description string
    has to be split and then the n variable in
    line 18 will change upon changing the language
    which will also change the language of the
    description.
    */
  const n = nObj[currentLanguage];
  const [currentImage, setCurrentImage] = useState(1);
  const [descOpen, setDescOpen] = useState(false);
  var imageMax = 3;
  const nextImage = () => {
    if (currentImage === imageMax) {
      setCurrentImage(1);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };
  const prevImage = () => {
    if (currentImage === 1) {
      setCurrentImage(imageMax);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const [loading, setLoading] = useState(false);

  const roomIsChosen = () => {
    setLoading(true);
    window.open(
      `https://wa.me/212661519014/?text=Hello I would like to ask for availability of a '${roomData.name}' from ${arrivalDate} to ${departureDate}.`
    );
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  return (
    <div className="room-card">
      <div className="room-card__picture">
        {[1, 2, 3].map((e) => (
          <div
            className={`room-card__image img-${e}${
              currentImage > e ? " passed" : ""
            }${currentImage === e ? " active" : ""}`}
            style={{
              backgroundImage: `url('./room-pics/${roomData.id}/${e}.jpg')`,
            }}
          ></div>
        ))}
        <div className="overlay">
          <div className="previous btn" onClick={prevImage}>
            <ion-icon name="chevron-back"></ion-icon>
          </div>
          <div className="next btn" onClick={nextImage}>
            <ion-icon name="chevron-forward"></ion-icon>
          </div>
          <div className="counter">
            <p>
              {currentImage}/{imageMax}
            </p>
          </div>
        </div>
      </div>
      <div className="room-card__description">
        <div className="room-card__description__name">
          <p>{roomData.name}</p>
        </div>
        <div className="room-card__description__options">
          <div className="room-card__description__options__option persons">
            <ion-icon name="person"></ion-icon>
            <p>Max. {roomData.maxP}</p>
          </div>
          <p
            className={`show-more${descOpen ? " open" : ""}`}
            onClick={() => setDescOpen(!descOpen)}
          >
            <Translate string="room.description" />
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
        </div>
        <div className="room-card__description__description">
          <p className={descOpen ? "description open" : "description"}>
            {roomData.description.split(";")[n]}
          </p>
        </div>
        <div className="room-card__description__footer">
          <div className="room-card__description__footer__price">
            <p className="main-price">{inCurrentCurrency(roomData.price)}</p>
            <p className="small-text">
              <Translate string="room.perNight" />
            </p>
          </div>
          <button
            className="booking-btn gold next-btn"
            onClick={roomIsChosen}
            disabled={loading}
          >
            {loading && (
              <img src={loadingSpinner} height={15} alt="Loading..." />
            )}
            {!loading && <Translate string="room.showRates" />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RoomCard;
/*
<div className="room-card__description">
    <div className="room-card__description__name">
        <p>{roomData.name}</p>
    </div>
    <div className="room-card__description__description">
        <p className={descOpen?'':'cut'}>
            {roomData.description.split(';')[n]}
        </p>
        <p 
            className={`show-more${descOpen?' open':''}`}
            onClick={() => setDescOpen(!descOpen)}
        >
            {descOpen?<Translate string="room.showLess" />:<Translate string="room.showMore" />}
            <ion-icon name="chevron-down-outline"></ion-icon>
        </p>
    </div>
    <div className="room-card__description__footer">
        <div className="room-card__description__footer__persons">
            <ion-icon name="person"></ion-icon>
            <p>MAX. {roomData.maxP}</p>
        </div>
        <button className="booking-btn gold next-btn" onClick={nextStep}>
            <Translate string="room.showRates" />
        </button>
    </div>
</div>

*/
