import React from "react";
import "./styles/Section.scss";

function Events() {
  let currentImage = 1;
  const imgCount = 1;
  const nextImage = () => {
    if (currentImage === imgCount) {
      currentImage = 1;
    } else {
      currentImage++;
    }
    showImage(currentImage);
  };
  const previousImage = () => {
    if (currentImage === 1) {
      currentImage = imgCount;
    } else {
      currentImage--;
    }
    showImage(currentImage);
  };
  const showImage = (n) => {
    if (n > imgCount) {
      console.log("img called for does not exist");
    }
    for (let i = 1; i <= imgCount; i++) {
      let imgElement = document.querySelector(`.banner-pic.pic-${i}`);
      if (i === n) {
        if (!imgElement.classList.contains("current")) {
          imgElement.classList.add("current");
        }
      } else {
        imgElement.classList.remove("current");
      }
    }
  };
  return (
    <div className="section-container events flipped" id="events">
      <div className="text-container">
        <div className="title" data-aos="fade-up" data-aos-duration="600">
          <p>EVENTS</p>
          <span></span>
        </div>
        <div className="content" data-aos="fade-up" data-aos-duration="800">
          <p className="text">
            Discover the perfect venue for your special events within the walls
            of our hotel. With its majestic architecture, elegant interiors, and
            impeccable service, our hotel provides an exceptional backdrop for
            weddings, corporate gatherings, and social celebrations. Our
            dedicated event planning team will ensure every detail is
            meticulously arranged, creating memories that will be cherished for
            a lifetime.
          </p>
          <div
            className="explore-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>VIEW UPCOMING EVENTS</p>
            <span></span>
          </div>
        </div>
      </div>
      <div className="picture-container" data-aos="fade-up">
        <div className="btn previous" onClick={previousImage}>
          <span className="previous"></span>
        </div>
        <div className="btn next" onClick={nextImage}>
          <span className="next"></span>
        </div>
        <div
          className="banner-pic pic-1 current"
          style={{ backgroundImage: "url(./pic-6.jpg)" }}
        ></div>
      </div>
    </div>
  );
}

export default Events;
