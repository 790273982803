import React from "react";
import ReactDOM from "react-dom";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function PayPal(props) {
  const { fulfillReservation, setLoading, totalPrice } = props;

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "EUR",
            value: totalPrice / 2,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    setLoading(true);
    fulfillReservation();
    return actions.order.capture();
  };

  return (
    <div className="paypal-wrapper">
      <PayPalButton
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />
    </div>
  );
}

export default PayPal;
