import Header from "./components/Header";
import Loading from "./components/Loading";
import "./components/styles/main.scss";
import BookingContent from "./components/BookingContent";
import { useState } from "react";
import Cookies from "js-cookie";
import { LocaleContext } from "./locale-context";
import Helmet from "react-helmet";
import CheckOut from "./components/CheckOut";
import Finish from "./components/Finish";
import moment from "moment";

function Book() {
  // language and currency setup
  const savedLang = Cookies.get("lang");
  const savedCurr = Cookies.get("curr");
  const currencies = [
    { nameAbr: "USD", symbol: "$" },
    { nameAbr: "EUR", symbol: "€" },
    { nameAbr: "GBP", symbol: "£" },
    { nameAbr: "AUD", symbol: "A$ " },
    { nameAbr: "MAD", symbol: "MAD " },
  ];
  const langs = [
    { name: "English (US)", nameAbr: "en" },
    { name: "Français", nameAbr: "fr" },
    { name: "Español", nameAbr: "es" },
    { name: "Italiano", nameAbr: "it" },
    { name: "Deutsch", nameAbr: "de" },
  ];
  let savedLang2, savedCurr2;
  if (
    savedLang &&
    langs.filter((lang) => lang.nameAbr === savedLang).length > 0
  ) {
    savedLang2 = savedLang;
  }
  if (
    savedCurr &&
    currencies.filter((curr) => curr.nameAbr === savedCurr).length > 0
  ) {
    savedCurr2 = savedCurr;
  }
  const [currentLanguage, setCurrentLanguage] = useState(savedLang2 || "en");
  const [currentCurrency, setCurrentCurrency] = useState(savedCurr2 || "EUR");
  const [currencyRatio, setCurrencyRatio] = useState(1);
  const inCurrentCurrency = (amount) => {
    if (currentCurrency === "EUR") {
      setCurrencyRatio(1);
    } else {
      fetch(`/convert`)
        .then((res) => res.json())
        .then((res) => {
          setCurrencyRatio(res[currentCurrency]);
        });
    }
    let symbol = currencies.filter(
      (currency) => currency.nameAbr === currentCurrency
    )[0].symbol;
    return `${symbol}${(amount * currencyRatio).toFixed(2)}`;
  };
  const changeCurrency = (to) => {
    if (currencies.filter((curr) => curr.nameAbr === to).length > 0) {
      Cookies.set("curr", to);
      setCurrentCurrency(to);
    } else {
      Cookies.set("curr", "USD");
      setCurrentCurrency("USD");
    }
  };
  const changeLanguage = (to) => {
    if (langs.filter((lang) => lang.nameAbr === to).length > 0) {
      setCurrentLanguage(to);
      Cookies.set("lang", to);
    } else {
      Cookies.set("lang", "en");
      setCurrentLanguage("en");
    }
  };
  // language and currency setup end.

  // booking steps setup
  const visibleDate = (date) => {
    let days = [];
    switch (currentLanguage) {
      case "en":
        days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
        break;
      case "fr":
        days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        break;
      case "de":
        days = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];
        break;
      case "es":
        days = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];
        break;
      case "it":
        days = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
        break;
      default:
        days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
        break;
    }
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let year = date.getYear() + 1900;
    return `${days[date.getDay()]} ${day}/${month}/${year}`;
  };
  const isDate = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };
  const savedArrival = Cookies.get("arr");
  const savedDeparture = Cookies.get("dep");
  let preStep, savedArr, savedDep;
  const today = new Date();
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(today.getDate() + 2);
  if (isDate(savedArrival) && isDate(savedDeparture)) {
    preStep = 2;
    savedArr = new Date(savedArrival);
    savedDep = new Date(savedDeparture);
    if (
      savedArr > savedDep ||
      savedArr.toDateString() === savedDep.toDateString()
    ) {
      savedArr = null;
      savedDep = null;
    }
  }
  const [currentStep, setCurrentStep] = useState(preStep || 1);
  const [shownStep, setShownStep] = useState(preStep || 1);
  const [arrivalDate, setArrivalDate] = useState(savedArr || today);
  const [departureDate, setDepartureDate] = useState(
    savedDep || dayAfterTomorrow
  );
  const [currentRoom, setCurrentRoom] = useState({});
  // booking steps setup end.

  //payment and booking submission
  const [client, setClient] = useState({});
  const [currentItems, setCurrentItems] = useState([]);

  // check-out
  const [inCheckOut, setInCheckOut] = useState(false);

  const [finished, setFinished] = useState(false);
  const [finishedWithError, setFinishedWithError] = useState(false);

  //   const sendMails = (reservation) => {
  //     console.log(client);
  //     const adminEmails = currentItems.map((item) =>
  //       sendReservationToAdmin({
  //         checkin_date: `${moment(item.arrivalDate).format(
  //           "MMMM Do YYYY"
  //         )}, 14:00:00 pm`,
  //         checkout_date: `${moment(item.departureDate).format(
  //           "MMMM Do YYYY"
  //         )}, 12:00:00 pm`,
  //         room_info: `Room ${currentRoom.id} - ${currentRoom.name} - ${currentRoom.price}€ per night`,
  //         adults_count: item.adults,
  //         children_count: item.children,
  //         total_in_euros: item.total,
  //         client_has_paid: `Client has paid ${
  //           item.total / 2
  //         }€ on the website through Paypal.`,
  //         rest_unpaid: `${item.total / 2}€`,
  //         client_name: client.name,
  //         client_email: client.email,
  //         client_nationality: client.nationality,
  //         client_requests: client.specialRequests,
  //         client_currency: client.currencyUsed,
  //         client_language: client.languageUsed,
  //         time_added: moment(reservation.timeAdded).format("LLLL"),
  //       })
  //     );

  //     const userEmails = currentItems.map((item) =>
  //       sendReservationToClient({
  //         client_name: client.name,
  //         checkin_date: `${moment(item.arrivalDate).format(
  //           "MMMM Do YYYY"
  //         )}, 14:00:00 pm`,
  //         checkout_date: `${moment(item.departureDate).format(
  //           "MMMM Do YYYY"
  //         )}, 12:00:00 pm`,
  //         room_info: `Room ${currentRoom.id} - ${currentRoom.name} - ${currentRoom.price}€ per night`,
  //         adults_count: item.adults,
  //         children_count: item.children,
  //         total_in_euros: item.total,
  //         client_has_paid: `You have paid ${
  //           item.total / 2
  //         }€ on the website through Paypal.`,
  //         rest_unpaid: `${item.total / 2}€`,
  //         to_email: client.email,
  //       })
  //     );

  //     return Promise.all([...adminEmails, ...userEmails]);
  //   };

  const fulfillReservation = () => {
    /* 
            What should this function do ?
            -> push client to database and get client id
            -> push reservation to database with client id
            -> send email reservation to user
            -> return stuff to interface
        */

    fetch(`/clients`, {
      method: "POST",
      body: JSON.stringify(client),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((client) => {
        let newReservation = {
          items: currentItems,
          clientID: client._id,
          timeAdded: new Date(),
        };
        fetch(`/reservations`, {
          method: "POST",
          body: JSON.stringify(newReservation),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        })
          .then((res) => res.json())
          .then((reservation) => {
            // sendMails(reservation)
            //   .then(() => {
            setFinished(true);
            setFinishedWithError(false);
            //   })
            //   .catch(() => {
            //     setFinished(false);
            //     setFinishedWithError(true);
            //   });
          })
          .catch((err) => {
            setFinished(true);
            setFinishedWithError(true);
          });
      })
      .catch((err) => {
        setFinished(true);
        setFinishedWithError(true);
      });
  };

  return (
    <LocaleContext.Provider value={currentLanguage}>
      <Helmet>
        <title>Booking - Palais Shazam</title>
      </Helmet>
      <div className="Booking-App">
        <Loading />
        {finished && (
          <Finish client={client} finishedWithError={finishedWithError} />
        )}
        {inCheckOut && (
          <CheckOut
            active={inCheckOut}
            fulfillReservation={fulfillReservation}
            totalPrice={currentItems.reduce((acc, item) => acc + item.total, 0)}
          />
        )}
        <Header
          currentStep={currentStep}
          shownStep={shownStep}
          setShownStep={setShownStep}
          langs={langs}
          currencies={currencies}
          changeLanguage={changeLanguage}
          changeCurrency={changeCurrency}
          language={currentLanguage}
          currency={currentCurrency}
        />
        <BookingContent
          shownStep={shownStep}
          setShownStep={setShownStep}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          arrivalDate={arrivalDate}
          departureDate={departureDate}
          setArrivalDate={setArrivalDate}
          setDepartureDate={setDepartureDate}
          currentLanguage={currentLanguage}
          currentCurrency={currentCurrency}
          inCurrentCurrency={inCurrentCurrency}
          currentRoom={currentRoom}
          setCurrentRoom={setCurrentRoom}
          currentItems={currentItems}
          setCurrentItems={setCurrentItems}
          setInCheckOut={setInCheckOut}
          setClient={setClient}
          visibleDate={visibleDate}
        />
        <div className="bg-img" style={{ backgroundImage: "url(./pic-1.jpg)" }}>
          <div className={`black${shownStep > 1 ? " visible" : ""}`}></div>
          <div className="booking-bg-effect"></div>
        </div>
      </div>
    </LocaleContext.Provider>
  );
}

export default Book;
