import React from "react";
import "./styles/Section.scss";

function Rooms() {
  let currentImage = 1;
  const imgCount = 3;
  const nextImage = () => {
    if (currentImage === imgCount) {
      currentImage = 1;
    } else {
      currentImage++;
    }
    showImage(currentImage);
  };
  const previousImage = () => {
    if (currentImage === 1) {
      currentImage = imgCount;
    } else {
      currentImage--;
    }
    showImage(currentImage);
  };
  const showImage = (n) => {
    if (n > imgCount) {
      console.log("img called for does not exist");
    }
    for (let i = 1; i <= imgCount; i++) {
      let imgElement = document.querySelector(`.banner-pic.pic-${i}`);
      if (i === n) {
        if (!imgElement.classList.contains("current")) {
          imgElement.classList.add("current");
        }
      } else {
        imgElement.classList.remove("current");
      }
    }
  };
  return (
    <div className="section-container rooms" id="rooms">
      <div className="text-container">
        <div className="title" data-aos="fade-up" data-aos-duration="600">
          <p>OUR ROOMS</p>
          <span></span>
        </div>
        <div className="content" data-aos="fade-up" data-aos-duration="800">
          <p className="text">
            Indulge in the opulence of our meticulously designed rooms and
            suites. Each space is thoughtfully adorned with traditional Moroccan
            motifs, creating an atmosphere of refined elegance. From
            breathtaking views of the cityscape to plush furnishings and modern
            amenities, our rooms offer a sanctuary of relaxation and
            tranquility, ensuring a truly rejuvenating stay.
          </p>
          <div
            className="explore-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>View our Rooms</p>
            <span></span>
          </div>
        </div>
      </div>
      <div className="picture-container" data-aos="fade-up">
        <div className="btn previous" onClick={previousImage}>
          <span className="previous"></span>
        </div>
        <div className="btn next" onClick={nextImage}>
          <span className="next"></span>
        </div>
        <div
          className="banner-pic pic-1 current"
          style={{ backgroundImage: "url(./pic-3.jpg)" }}
        ></div>
        <div
          className="banner-pic pic-2"
          style={{ backgroundImage: "url(./pic-4.jpg)" }}
        ></div>
        <div
          className="banner-pic pic-3"
          style={{ backgroundImage: "url(./pic-5.jpg)" }}
        ></div>
      </div>
    </div>
  );
}

export default Rooms;
