import "./styles/BookingRates.scss";
import { useState, useEffect } from "react";
import loadingSpinner from "./../images/loadingSpinner.gif";
import Translate from "./Translate";
import loadingDarkBG from "./../images/spinnerDarkBG.gif";
import Cookies from "js-cookie";
import Product from "./Product";
import shuttle from "./../images/shuttle.jpg";
import romantic from "./../images/romantic.jpg";
import massage from "./../images/massage.jpg";

function BookingRates(props) {
  const {
    currentStep,
    shownStep,
    nextStep,
    arrivalDate,
    departureDate,
    inCurrentCurrency,
    setCurrentItems,
    currentItems,
    currentRoom,
    visibleDate,
    setShownStep,
    currentLanguage,
    included,
  } = props;

  const products = [
    {
      id: 1,
      title: "Shuttle",
      description: "Shuttle",
      picture: shuttle,
      price: 15,
      quantity: "perPerson",
    },
    {
      id: 2,
      title: "Romantic Pack",
      description: "Romantic Pack",
      picture: romantic,
      price: 15,
      quantity: "once",
    },
    {
      id: 3,
      title: "Massage Session",
      description: "Massage Session",
      picture: massage,
      price: 15,
      quantity: "once",
    },
  ];

  const isDate = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [available, setAvailable] = useState(false);

  const [ratesLoading, setRatesLoading] = useState(false);
  const [ratesIncludedOpen, setRatesIncludedOpen] = useState(false);

  const [extraBed, setExtraBed] = useState(false);
  const [adultsCount, setAdultsCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(0);
  const adultsCountChange = (e) => {
    setRatesLoading(true);
    let newValue = e.target.value;
    if (newValue == 0 && childrenCount == 0) {
      setChildrenCount(1);
      setExtraBed(false);
    } else {
      let newCapacity = parseInt(childrenCount) + parseInt(newValue);
      if (newCapacity > currentRoom.maxP) {
        setChildrenCount(currentRoom.maxP - parseInt(newValue));
        setExtraBed(true);
      } else if (newCapacity === currentRoom.maxP) {
        setExtraBed(true);
      } else {
        setExtraBed(false);
      }
    }
    setAdultsCount(newValue);
  };
  const childrenCountChange = (e) => {
    setRatesLoading(true);
    let newValue = e.target.value;
    if (newValue == 0 && adultsCount == 0) {
      setAdultsCount(1);
      setExtraBed(false);
    } else {
      let newCapacity = parseInt(adultsCount) + parseInt(newValue);
      if (newCapacity > currentRoom.maxP) {
        setAdultsCount(currentRoom.maxP - parseInt(newValue));
        setExtraBed(true);
      } else if (newCapacity === currentRoom.maxP) {
        setExtraBed(true);
      } else {
        setExtraBed(false);
      }
    }
    setChildrenCount(newValue);
  };
  const [includedProducts, setIncludedProducts] = useState([]);
  let total = 0;
  total += parseFloat(currentRoom.price);
  let nightsCount = Math.ceil(
    Math.abs(departureDate - arrivalDate) / (1000 * 60 * 60 * 24)
  );
  if (extraBed) {
    total += 15 / nightsCount;
  }
  let userOccupancy = parseInt(adultsCount) + parseInt(childrenCount);
  products.map((product) => {
    if (includedProducts.includes(product.id)) {
      if (product.quantity === "perPerson") {
        total += (product.price * userOccupancy) / nightsCount;
      } else if (product.quantity === "once") {
        total += product.price / nightsCount;
      }
    }
  });

  const [loadingCheckOutBtn, setLoadingCheckOutBtn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRatesLoading(false);
    }, 600);
  }, [ratesLoading]);

  useEffect(() => {
    if (shownStep === 3) {
      setLoading(true);
      setError(null);
      setAvailable(false);
      setIncludedProducts([]);
      setAdultsCount(1);
      setChildrenCount(0);
      setExtraBed(0);

      let arrival, departure;
      let savedArrival = Cookies.get("arr");
      let savedDeparture = Cookies.get("dep");
      if (isDate(savedArrival) && isDate(savedDeparture)) {
        arrival = savedArrival;
        departure = savedDeparture;
        fetch(`/reservations/interval?arr=${arrival}&dep=${departure}`)
          .then((res) => res.json())
          .then((reservations) => {
            let takenRooms = [];
            let userArrival = new Date(arrival);
            let userDeparture = new Date(departure);
            let inInterval = (date) => {
              return date > userArrival && date < userDeparture;
            };
            let isUnavailable = (resArr, resDep) => {
              return (
                (resArr <= userArrival && resDep >= userDeparture) ||
                inInterval(resArr) ||
                inInterval(resDep)
              );
            };
            reservations.map((reservation) => {
              reservation.items.map((item) => {
                if (
                  isUnavailable(
                    new Date(item.arrivalDate),
                    new Date(item.departureDate)
                  )
                ) {
                  takenRooms.push(item.roomID);
                }
              });
            });
            currentItems.map((item) => {
              if (!takenRooms.includes(item.roomID)) {
                if (
                  isUnavailable(
                    new Date(item.arrivalDate),
                    new Date(item.departureDate)
                  )
                ) {
                  takenRooms.push(item.roomID);
                }
              }
            });

            setAvailable(!takenRooms.includes(currentRoom._id));
            setLoading(false);
            setError(null);
          })
          .catch((err) => {
            setLoading(false);
            setError(<Translate string="rates.error.server" />);
          });
      } else {
        setLoading(false);
        setError(<Translate string="rates.error.dates" />);
      }
    }
  }, [shownStep]);

  //room card setup
  const nObj = { en: 0, fr: 1, es: 2, it: 3, de: 4 };
  const n = nObj[currentLanguage];
  const [currentImage, setCurrentImage] = useState(1);
  const [descOpen, setDescOpen] = useState(false);
  var imageMax = 3;
  const nextImage = () => {
    if (currentImage === imageMax) {
      setCurrentImage(1);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };
  const prevImage = () => {
    if (currentImage === 1) {
      setCurrentImage(imageMax);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };
  //room card setup end

  const moveToCheckOut = () => {
    setLoadingCheckOutBtn(true);
    let currentItem = {
      arrivalDate: arrivalDate,
      departureDate: departureDate,
      roomID: currentRoom._id,
      adults: adultsCount,
      children: childrenCount,
      total: total * nightsCount,
      products: includedProducts,
    };
    setCurrentItems([...currentItems, currentItem]);
    setTimeout(() => {
      nextStep();
      setLoadingCheckOutBtn(false);
    }, 800);
  };

  if (currentStep >= 3) {
    if (error || loading) {
      return (
        <div
          className={`container rates full-screen${
            shownStep === 3 ? " slide-in" : ""
          }${shownStep > 3 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="rates.title" />
          </p>
          <p className="sub-title">
            <button className="disabled-btn" onClick={() => setShownStep(1)}>
              <ion-icon name="calendar-outline"></ion-icon>
              {visibleDate(arrivalDate)} &rarr; {visibleDate(departureDate)}
            </button>
          </p>
          {loading && <img src={loadingDarkBG} alt="Loading..." height={80} />}
          {error && <p className="error-msg">{error}</p>}
        </div>
      );
    } else {
      return (
        <div
          className={`container rates full-screen${
            shownStep === 3 ? " slide-in" : ""
          }${shownStep > 3 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="rates.title" />
          </p>
          <p className="sub-title">
            <button className="disabled-btn" onClick={() => setShownStep(1)}>
              <ion-icon name="calendar-outline"></ion-icon>
              {visibleDate(arrivalDate)} &rarr; {visibleDate(departureDate)}
            </button>
          </p>
          <div className="wrapper">
            <div className="room-card">
              <div className="room-card__picture">
                {[1, 2, 3].map((e) => (
                  <div
                    className={`room-card__image img-${e}${
                      currentImage > e ? " passed" : ""
                    }${currentImage === e ? " active" : ""}`}
                    style={{
                      backgroundImage: `url('./room-pics/${currentRoom.id}/${e}.jpg')`,
                    }}
                  ></div>
                ))}
                <div className="overlay">
                  <div className="previous btn" onClick={prevImage}>
                    <ion-icon name="chevron-back"></ion-icon>
                  </div>
                  <div className="next btn" onClick={nextImage}>
                    <ion-icon name="chevron-forward"></ion-icon>
                  </div>
                  <div className="counter">
                    <p>
                      {currentImage}/{imageMax}
                    </p>
                  </div>
                </div>
              </div>
              <div className="room-card__description">
                <div className="room-card__name">
                  <p>{currentRoom.name}</p>
                </div>
                <div className="room-card__maxP">
                  <ion-icon name="person"></ion-icon>
                  <p>Max. {currentRoom.maxP}</p>
                </div>
                <div className="room-card__actual-description">
                  <p className="description">
                    {descOpen
                      ? currentRoom.description.split(";")[n]
                      : currentRoom.description
                          .split(";")
                          [n].split(" ")
                          .slice(0, 20)
                          .join(" ") + "..."}
                  </p>
                </div>
                <p
                  className={`show-more${descOpen ? " open" : ""}`}
                  onClick={() => setDescOpen(!descOpen)}
                >
                  <Translate string="room.description" />
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </p>
              </div>
            </div>
            {!available ? (
              <div className="unavailable-error">
                <p className="unavailable-msg">
                  <Translate string="rates.error.unavailable" />
                </p>
                <button
                  className="booking-btn gold unavailable-btn"
                  onClick={() => setShownStep(2)}
                >
                  <Translate string="rates.error.unavailable.button" />
                </button>
              </div>
            ) : (
              <div className="grid-wrapper">
                <div className="products section">
                  <p className="section__title">
                    <Translate string="rates.products.title" />
                  </p>
                  <div className="section__wrapper">
                    {products.map((product, i) => (
                      <Product
                        key={i}
                        product={product}
                        inCurrentCurrency={inCurrentCurrency}
                        setRatesLoading={setRatesLoading}
                        includedProducts={includedProducts}
                        setIncludedProducts={setIncludedProducts}
                      />
                    ))}
                  </div>
                </div>
                <div className="right-column">
                  <div className="occupancy section">
                    <p className="section__title">
                      <Translate string="rates.occupancy.title" />
                    </p>
                    <div className="section__wrapper">
                      <div className="input-wrapper">
                        <label>
                          <Translate string="rates.occupancy.adults" />
                        </label>
                        <select
                          type="number"
                          value={adultsCount}
                          onChange={(e) => adultsCountChange(e)}
                        >
                          {[...Array(currentRoom.maxP + 1)].map((e, i) => (
                            <option key={i}>{i}</option>
                          ))}
                        </select>
                      </div>
                      <div className="input-wrapper">
                        <label>
                          <Translate string="rates.occupancy.children" />
                        </label>
                        <select
                          type="number"
                          value={childrenCount}
                          onChange={(e) => childrenCountChange(e)}
                        >
                          {[...Array(currentRoom.maxP + 1)].map((e, i) => (
                            <option key={i}>{i}</option>
                          ))}
                        </select>
                        <p className="note">
                          <Translate string="rates.occupancy.note" />
                        </p>
                      </div>
                      {extraBed ? (
                        <p className="extra-bed">
                          <Translate string="rates.occupancy.extraBed" /> 1
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="rates section">
                    <p className="section__title">
                      <Translate string="rates.rates.title" />
                    </p>
                    {ratesLoading ? (
                      <img src={loadingDarkBG} alt="Loading..." height={75} />
                    ) : (
                      <div className="section__wrapper">
                        <div className="rates-section__header">
                          <h3>BAR Direct</h3>
                          <p
                            className={`show-more${
                              ratesIncludedOpen ? " open" : ""
                            }`}
                            onClick={() =>
                              setRatesIncludedOpen(!ratesIncludedOpen)
                            }
                          >
                            <Translate string="rates.whatsIncluded" />
                            <ion-icon name="chevron-down-outline"></ion-icon>
                          </p>
                        </div>
                        <p
                          className={`included${
                            ratesIncludedOpen ? " open" : ""
                          }`}
                          dangerouslySetInnerHTML={{ __html: included[n] }}
                        ></p>
                        <div className="rates-section__footer">
                          <div className="price">
                            <p className="main-price">
                              {inCurrentCurrency(total)}
                            </p>
                            <p className="small-text">
                              <Translate string="room.perNight" />
                            </p>
                          </div>
                          <button
                            className="booking-btn gold next-btn"
                            onClick={moveToCheckOut}
                            disabled={loadingCheckOutBtn}
                          >
                            {loadingCheckOutBtn ? (
                              <img
                                src={loadingSpinner}
                                alt="Loading..."
                                height={15}
                              />
                            ) : (
                              <Translate string="rates.rates.bookNow" />
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  } else {
    return (
      <div
        className={`container${shownStep === 3 ? " slide-in" : ""}${
          shownStep > 3 ? " passed" : ""
        }`}
      >
        <p>
          An error occurred. This should not have happened... Please refresh the
          page and try again.
        </p>
      </div>
    );
  }
}

export default BookingRates;
