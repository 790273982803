import "./styles/BookingSummary.scss";
import Translate from "./Translate";
import { useState, useEffect } from "react";
import spinner from "./../images/spinnerDarkBG.gif";
import loadingSpinner from "./../images/loadingSpinner.gif";
import Item from "./Item";

function BookingSummary(props) {
  const {
    currentStep,
    shownStep,
    nextStep,
    setShownStep,
    inCurrentCurrency,
    currentItems,
    n,
    included,
  } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [descOpen, setDescOpen] = useState(false);

  // check-out btn
  const [checkOutBtnLoading, setCheckOutBtnLoading] = useState(false);
  const checkOut = () => {
    setCheckOutBtnLoading(true);
    setTimeout(() => {
      nextStep();
      setCheckOutBtnLoading(false);
    }, 800);
  };

  const addAnotherItem = () => {
    setShownStep(2);
  };
  let total = 0;
  currentItems.map((item) => {
    total += item.total;
  });
  useEffect(() => {
    setLoading(true);
    setError(null);
    if (shownStep === 4) {
      fetch(`/rooms`)
        .then((res) => res.json())
        .then((rooms) => {
          setRooms(rooms);
          setTimeout(() => {
            setError(null);
            setLoading(false);
          }, 800);
        })
        .catch((err) => {
          setTimeout(() => {
            setError("rooms.error");
            setLoading(false);
          }, 800);
        });
    }
  }, [shownStep]);

  const roomName = (id) => {
    return rooms.filter((room) => room._id === id)[0].name;
  };
  if (currentStep >= 4) {
    if (loading || error) {
      return (
        <div
          className={`container summary full-screen${
            shownStep === 4 ? " slide-in" : ""
          }${shownStep > 4 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="summary.title" />
          </p>
          <p className="error-loading-msg">
            {loading && <img height="80" src={spinner} alt="Loading..." />}
            {error && <Translate string={error} />}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={`container summary full-screen${
            shownStep === 4 ? " slide-in" : ""
          }${shownStep > 4 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="summary.title" />
          </p>
          <div className="items-wrapper">
            {currentItems.map((item, i) => (
              <Item
                key={i}
                item={item}
                room={rooms.filter((room) => room._id === item.roomID)[0]}
                roomName={roomName}
                rooms={rooms}
                n={n}
                included={included}
                inCurrentCurrency={inCurrentCurrency}
              />
            ))}
            <button className="disabled-btn with-icon" onClick={addAnotherItem}>
              <ion-icon name="add"></ion-icon>
              <Translate string="summary.addAnotherItem" />
            </button>
            <div className="total-card">
              {currentItems.map((item, i) => (
                <div className="item-price" key={i}>
                  <p className="item-name">{roomName(item.roomID)}</p>
                  <p className="item-total">{inCurrentCurrency(item.total)}</p>
                </div>
              ))}
              <div className="total-price">
                <p className="total-name">Total</p>
                <p className="total-total">{inCurrentCurrency(total)}</p>
              </div>
            </div>
            <div className="checkout-btn">
              <button
                className="booking-btn gold"
                onClick={checkOut}
                disabled={checkOutBtnLoading}
              >
                {checkOutBtnLoading ? (
                  <img src={loadingSpinner} height={15} alt="Loading..." />
                ) : (
                  <Translate string="summary.checkOut" />
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div
        className={`container${shownStep === 4 ? " slide-in" : ""}${
          shownStep > 4 ? " passed" : ""
        }`}
      >
        <p>
          An error occurred. This should not have happened... Please refresh the
          page and try again.
        </p>
      </div>
    );
  }
}

export default BookingSummary;
