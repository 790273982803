import React, { useState } from "react";
import "./styles/BookingForm.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";

function BookingForm(props) {
  const { setError } = props;
  const currentDate = new Date();
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const isDate = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };
  const checkAvailability = () => {
    if (
      arrivalDate &&
      departureDate &&
      isDate(arrivalDate) &&
      isDate(departureDate) &&
      departureDate.toDateString() !== arrivalDate.toDateString() &&
      departureDate > arrivalDate
    ) {
      Cookies.set("arr", String(arrivalDate), { expires: 1 });
      Cookies.set("dep", String(departureDate), { expires: 1 });
      window.location.replace("/book");
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="booking-form" data-aos="fade-up" data-aos-duration="1500">
        <div className="form-container">
          <div className="date-input-wrapper">
            <DatePicker
              selected={arrivalDate}
              onChange={(date) => setArrivalDate(date)}
              selectsStart
              startDate={arrivalDate}
              endDate={departureDate}
              minDate={currentDate}
              dateFormat="dd/MM/yyyy"
              popperClassName="calendar-popper"
              placeholderText="Arrival"
              className="date-input"
            />
          </div>
          <div className="date-input-wrapper">
            <DatePicker
              selected={departureDate}
              onChange={(date) => setDepartureDate(date)}
              selectsEnd
              startDate={arrivalDate}
              endDate={departureDate}
              minDate={arrivalDate || currentDate}
              dateFormat="dd/MM/yyyy"
              popperClassName="calendar-popper"
              placeholderText="Departure"
              className="date-input"
            />
          </div>
          <button onClick={checkAvailability}>Check Availability</button>
        </div>
      </div>
    </>
  );
}

export default BookingForm;
