import './styles/Finish.scss'
import Translate from './Translate'

function Finish(props) {
	const {
		finishedWithError, client
	} = props
	return (
		<div className="Finish">
			<div className="content">
				<div className="name-flex">
					<ion-icon name={finishedWithError?'close':'checkmark'}></ion-icon>
					<p>
						{finishedWithError?
							<Translate string="finish.title.error" /> :
							<>
								<Translate string="finish.title.success" />{client.name.split(' ')[0]}!
							</>
						}
					</p>
				</div>
				<div className="description">
					{finishedWithError ?
						<p>
							<Translate string="finish.description.error1" />
							{client.name.split(' ')[0]}. 
							<Translate string="finish.description.error2" />
							<br />
							<span>- The Palais Shazam Team</span>
						</p> :
						<p>
							<Translate string="finish.description.success" />
							<br />
							<span>- The Palais Shazam Team</span>
						</p>
					}
				</div>
				<div className="buttons-wrapper">
					<button
						className="booking-btn gold with-icon"
						onClick={() => window.location.replace('/')}
					>
						<ion-icon name="arrow-back"></ion-icon>
						<Translate string="finish.actions.goBackHome" />
					</button>
					<button
						className="booking-btn reversed with-icon"
						onClick={() => window.location.replace('mailto:palaisshazam@gmail.com')}
					>
						<ion-icon name="mail-outline"></ion-icon>
						<Translate string="finish.actions.contactUs" />
					</button>
				</div>
			</div>
		</div>
	)
}

export default Finish
