import React from "react";
import "./styles/Section.scss";

function Pool() {
  let currentImage = 1;
  const imgCount = 1;
  const nextImage = () => {
    if (currentImage === imgCount) {
      currentImage = 1;
    } else {
      currentImage++;
    }
    showImage(currentImage);
  };
  const previousImage = () => {
    if (currentImage === 1) {
      currentImage = imgCount;
    } else {
      currentImage--;
    }
    showImage(currentImage);
  };
  const showImage = (n) => {
    if (n > imgCount) {
      console.log("img called for does not exist");
    }
    for (let i = 1; i <= imgCount; i++) {
      let imgElement = document.querySelector(`.banner-pic.pic-${i}`);
      if (i === n) {
        if (!imgElement.classList.contains("current")) {
          imgElement.classList.add("current");
        }
      } else {
        imgElement.classList.remove("current");
      }
    }
  };
  return (
    <div className="section-container pool" id="pool">
      <div className="text-container">
        <div className="title" data-aos="fade-up" data-aos-duration="600">
          <p>SWIMMING POOL</p>
          <span></span>
        </div>
        <div className="content" data-aos="fade-up" data-aos-duration="800">
          <p className="text">
            Our hotel boasts a luxurious swimming pool, inviting you to unwind
            and soak up the Moroccan sun. Whether you wish to take a refreshing
            dip or bask in the serene ambience of the poolside, our oasis is the
            perfect retreat. With its exquisite design and tranquil
            surroundings, our publicly accessible pool offers an idyllic escape
            from the bustling city, allowing you to rejuvenate both body and
            mind.
          </p>
          <div
            className="explore-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>LEARN MORE</p>
            <span></span>
          </div>
        </div>
      </div>
      <div className="picture-container" data-aos="fade-up">
        <div className="btn previous" onClick={previousImage}>
          <span className="previous"></span>
        </div>
        <div className="btn next" onClick={nextImage}>
          <span className="next"></span>
        </div>
        <div
          className="banner-pic pic-1 current"
          style={{ backgroundImage: "url(./pic-2.jpg)" }}
        ></div>
      </div>
    </div>
  );
}

export default Pool;
