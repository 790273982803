import {useState} from 'react'
import './styles/BookingDates.scss'
import DatePicker from 'react-datepicker'
import loadingSpinner from './../images/loadingSpinner.gif'
import Cookies from 'js-cookie'
import Translate from './Translate'

function BookingDates(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const currentDate = new Date()
    const {
        shownStep, nextStep,
        arrivalDate, departureDate,
        setArrivalDate, setDepartureDate
    } = props
    const errorCodes = {
        1: <Translate string="dates.error.equal" />,
        2: <Translate string="dates.error.invalid" />,
        3: <Translate string="dates.error.server" />,
        4: <Translate string="dates.error.idk" />,
        5: <Translate string="dates.error.timeTravel" />
    }
    const isDate = date => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }
    const ValidateDates = () => { // date input validation
        setLoading(true)
        setError('')
        if ( isDate(arrivalDate)
            && isDate(departureDate)
            && departureDate.toDateString() !== arrivalDate.toDateString()
            && departureDate > arrivalDate
        ) {
            setError('')
            Cookies.set('arr', String(arrivalDate), {expires: 1})
            Cookies.set('dep', String(departureDate), {expires: 1})
            setTimeout(() => {
                nextStep()
                setTimeout(()=>{
                    setLoading(false)
                }, 1000)
            }, 500)
        } else {
            switch (true) {
                case (departureDate.toDateString() === arrivalDate.toDateString()):
                    setError(1)
                    break;
                case (!isDate(arrivalDate) || !isDate(departureDate)):
                    setError(2)
                    break;
                case (departureDate < arrivalDate):
                    setError(5)
                    break;
                default:
                    setError(4)
            }
            setLoading(false)
        }
    }
    return (
        <div 
        className={`container dates${shownStep===1?' slide-in':''}${shownStep>1?' passed':''}`}
        >
            <div className="date-range-wrapper">
                {error && 
                <div className={`error-note`}>
                    <p>{errorCodes[error]}</p>
                </div>
                }
                <div className="date-range-arrival">
                    <p>
                        <Translate string="dates.arrival" />
                    </p>
                    <DatePicker 
                            selected={arrivalDate}
                            onChange={date => {setError('');setArrivalDate(date)}}
                            selectsStart
                            startDate={arrivalDate}
                            endDate={departureDate}
                            minDate={currentDate}
                            dateFormat="MMMM d, yyyy"
                            popperClassName="booking-calendar-popper"
                            placeholderText="Arrival"
                            className="date-input"
                        />
                </div>
                <div className="date-range-departure">
                    <p>
                        <Translate string="dates.departure" />
                    </p>
                    <DatePicker 
                            selected={departureDate}
                            onChange={date => {setError('');setDepartureDate(date)}}
                            selectsEnd
                            startDate={arrivalDate}
                            endDate={departureDate}
                            minDate={arrivalDate || currentDate}
                            dateFormat="MMMM d, yyyy"
                            popperClassName="booking-calendar-popper"
                            placeholderText="Departure"
                            className="date-input"
                        />
                </div>
            </div>
            <button 
                className={`booking-btn gold next-btn`} 
                onClick={ValidateDates}
                disabled={loading}
            >
                <img className={loading?'visible':''} src={loadingSpinner} />
                {!loading && <Translate string="booking.next" />}
            </button>
        </div>
    )
}

export default BookingDates
