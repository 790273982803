import "./styles/CheckOut.scss";
import { useState, useEffect } from "react";
import loadingSpinner from "./../images/loadingSpinner.gif";
import Paypal from "./PayPal";
import Translate from "./Translate";

function CheckOut(props) {
  const { active, fulfillReservation, totalPrice } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={`check-out${loading ? " loading" : ""}`}>
      {loading && <img src={loadingSpinner} alt="Loading..." height={50} />}
      {loading && <p className="loading-msg">Processing...</p>}
      {!loading && (
        <h3 className="title">
          <Translate string="checkOut.title" />
        </h3>
      )}
      {!loading && (
        <Paypal
          fulfillReservation={fulfillReservation}
          setLoading={setLoading}
          totalPrice={totalPrice}
        />
      )}
    </div>
  );
}

export default CheckOut;
