import icon from './../images/icon.png'
import './styles/Header.scss'
import {useEffect, useState} from 'react'
import Translate from './Translate'

function Header(props) {
    const {
        currentStep, shownStep, 
        setShownStep, 
        langs, currencies,
        changeCurrency, changeLanguage,
        currency, language
    } = props
    const currentLanguageVisibleName = langs.filter(lang => lang.nameAbr === language)[0].name
    const [currMenuShown, setCurrMenuShown] = useState(false)
    const [langMenuShown, setLangMenuShown] = useState(false)
    const [mobileMenuShown, setMobileMenuShown] = useState(false)
    return (
        <header className={`Booking-Header${currMenuShown||langMenuShown?' menu-open':''}`}>
            <div 
                className={`mobile-menu-effect${mobileMenuShown?' shown':''}`}
                onClick={() => setMobileMenuShown(!mobileMenuShown)}
            ></div>
            <div className="Top-Header">
                <div 
                    className="logo"
                    onClick={() => window.location.replace('/')}
                >
                    <img className="icon" src={icon} alt="ICON" />
                    <p className="hide-for-mobile">Palais Shazam</p>
                </div>
                <div className="right-side">
                    <div className="options">
                        <div
                            className={currMenuShown?'option currency open':'option currency'}
                            onClick={()=>{setCurrMenuShown(!currMenuShown);setLangMenuShown(false)}}
                        >
                            <div className="desktop">
                                <p>{currency}</p>
                                <ion-icon name="chevron-down-outline"></ion-icon>
                            </div>
                            <div className={currMenuShown?"dropdown curr open": "dropdown curr"}>
                                {currencies.map(curr => (
                                    <div 
                                        className={`dropdown-option${curr.nameAbr===currency?' current': ''}`} 
                                        key={curr.nameAbr}
                                        onClick={()=>{changeCurrency(`${curr.nameAbr}`);setCurrMenuShown(false)}}
                                    >
                                        <p>{curr.nameAbr} ({curr.symbol.trim()})</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div 
                            className={langMenuShown?'option language open':'option language'}
                            onClick={()=>{setLangMenuShown(!langMenuShown);setCurrMenuShown(false)}}
                        >
                            <div className="desktop hide-for-mobile">
                                <p>{currentLanguageVisibleName}</p>
                                <ion-icon name="chevron-down-outline"></ion-icon>
                            </div>
                            <div className="mobile hide-for-desktop">
                                <ion-icon name="globe-outline"></ion-icon>
                            </div>
                            <div className={langMenuShown?"dropdown lang open": "dropdown lang"}>
                                {langs.map(lang => (
                                    <div 
                                        className={`dropdown-option${lang.nameAbr===language?' current': ''}`} 
                                        key={lang.nameAbr}
                                        onClick={()=>{changeLanguage(`${lang.nameAbr}`);setLangMenuShown(false)}}
                                    >
                                        <p>{lang.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="escape" onClick={()=>window.location.replace('/')}>
                        <div className="escape-icon">
                            <ion-icon name="close-outline"></ion-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Progress-Header">
                <div style={{display:'none'}}>
                    <ion-icon name="checkmark"></ion-icon>
                </div>
                <ul 
                    className={`steps${mobileMenuShown?' shown':''}`}
                    onClick={() => setMobileMenuShown(!mobileMenuShown)}
                >
                    <li 
                        className={`${currentStep>=1?'active':''}${shownStep===1?' current':''}${shownStep<1&&currentStep>=1?' available': ''}`}
                        onClick={() => {
                            if (currentStep>1 || shownStep<currentStep && currentStep>=1) {
                                setShownStep(1)
                            }
                        }}
                    >
                        <ion-icon name={`${shownStep>1?'checkmark':'calendar'}`}></ion-icon>
                        <p>
                            <Translate string="booking.header.dates" />
                        </p>
                    </li>
                    <span className={shownStep>=2?'active':''}></span>
                    <li 
                        className={`${currentStep>=2?'active':''}${shownStep===2?' current':''}${shownStep<2&&currentStep>=2?' available': ''}`}
                        onClick={() => {
                            if (currentStep>2 || shownStep<currentStep && currentStep>=2) {
                                setShownStep(2)
                            }
                        }}
                    >
                        <ion-icon name={`${shownStep>2?'checkmark':'bed'}`}></ion-icon>
                        <p>
                        <Translate string="booking.header.roomChoice" />
                        </p>
                    </li>
                    <span className={shownStep>=3?'active':''}></span>
                    <li 
                        className={`${currentStep>=3?'active':''}${shownStep===3?' current':''}${shownStep<3&&currentStep>=3?' available': ''}`}
                        onClick={() => {
                            if (currentStep>3 || shownStep<currentStep && currentStep>=3) {
                                setShownStep(3)
                            }
                        }}
                    >
                        <ion-icon name={`${shownStep>3?'checkmark':'cash'}`}></ion-icon>
                        <p>
                            <Translate string="booking.header.rates" />
                        </p>
                    </li>
                    <span className={shownStep>=4?'active':''}></span>
                    <li 
                        className={`${currentStep>=4?'active':''}${shownStep===4?' current':''}${shownStep<4&&currentStep>=4?' available': ''}`}
                        onClick={() => {
                            if (currentStep>4 || (shownStep<currentStep && currentStep>=4)) {
                                setShownStep(4)
                            }
                        }}
                    >
                        <ion-icon name={`${shownStep>4?'checkmark':'cart'}`}></ion-icon>
                        <p>
                            <Translate string="booking.header.summary" />
                        </p>
                    </li>
                    <span className={shownStep>=5?'active':''}></span>
                    <li 
                        className={`${currentStep>=5?'active':''}${shownStep===5?' current':''}${shownStep<5&&currentStep>=5?' available': ''}`}
                        onClick={() => {
                            if (currentStep>5 || shownStep<currentStep && currentStep>=5) {
                                setShownStep(5)
                            }
                        }}
                    >
                        <ion-icon name="person"></ion-icon>
                        <p>
                            <Translate string="booking.header.details" />
                        </p>
                    </li>
                    <div className="hide-for-desktop arrow">
                        <p>{shownStep}/5</p>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </ul>
            </div>
        </header>
    )
}

export default Header
