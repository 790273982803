import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home/Home";
import Book from "./Book/Book";
import FourOhFour from "./FourOhFour";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/book">
            <Book />
          </Route>
          <Route exact path="/#*">
            <Home />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/*">
            <FourOhFour />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
