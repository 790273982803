import React from "react";
import "./styles/Section.scss";

function Restaurant() {
  let currentImage = 1;
  const imgCount = 1;
  const nextImage = () => {
    if (currentImage === imgCount) {
      currentImage = 1;
    } else {
      currentImage++;
    }
    showImage(currentImage);
  };
  const previousImage = () => {
    if (currentImage === 1) {
      currentImage = imgCount;
    } else {
      currentImage--;
    }
    showImage(currentImage);
  };
  const showImage = (n) => {
    if (n > imgCount) {
      console.log("img called for does not exist");
    }
    for (let i = 1; i <= imgCount; i++) {
      let imgElement = document.querySelector(`.banner-pic.pic-${i}`);
      if (i === n) {
        if (!imgElement.classList.contains("current")) {
          imgElement.classList.add("current");
        }
      } else {
        imgElement.classList.remove("current");
      }
    }
  };
  return (
    <div className="section-container flipped restaurant" id="restaurant">
      <div className="text-container">
        <div className="title" data-aos="fade-up" data-aos-duration="600">
          <p>RESTAURANT</p>
          <span></span>
        </div>
        <div className="content" data-aos="fade-up" data-aos-duration="800">
          <p className="text">
            Experience the rich tapestry of Moroccan cuisine at our hotel
            restaurant. Delight in a symphony of tantalizing flavors, as our
            skilled chefs craft traditional dishes using the freshest local
            ingredients. From aromatic tagines to savory couscous and delectable
            pastries, every bite is a celebration of the vibrant culinary
            heritage of Morocco. Prepare your taste buds for an unforgettable
            dining experience.
          </p>
          <div
            className="explore-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>View our Menu</p>
            <span></span>
          </div>
        </div>
      </div>
      <div className="picture-container" data-aos="fade-up">
        <div className="btn previous" onClick={previousImage}>
          <span className="previous"></span>
        </div>
        <div className="btn next" onClick={nextImage}>
          <span className="next"></span>
        </div>
        <div
          className="banner-pic pic-1 current"
          style={{ backgroundImage: "url(./new/pic-6.jpeg)" }}
        ></div>
      </div>
    </div>
  );
}

export default Restaurant;
