import { useState } from 'react'
import Translate from './Translate'

function Item(props) {
	const { 
		item, room,
		roomName  , n, included,
		inCurrentCurrency
	} = props
	const [ includedOpen, setIncludedOpen ] = useState(false)


	// date

	const visibleDate = (date) => {
		let all = [
			['Sun','Mon','Tues','Wed','Thu','Fri','Sat'],
			['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
			['Zo','Ma','Di','Wo','Do','Vr','Za'],
			['Do','Lu','Ma','Mi','Ju','Vi','Sa'],
			['Dom','Lun','Mar','Mer','Gio','Ven','Sab']
		]
        let days = all[n]
        let day = date.getDate()<10?`0${date.getDate()}`:date.getDate()
        let month = date.getMonth()+1<10?`0${date.getMonth()+1}`:date.getMonth()+1
        let year = date.getYear() + 1900
        return `${days[date.getDay()]} ${day}/${month}/${year}`
    }

	return (
		<div className="item">
			<div className="item__picture">
				<div 
					className="item__img"
					style={{backgroundImage: `url('./room-pics/${room.id}/1.jpg')`}}
				></div>
			</div>
			<div className="item__description">
				<div className="item__description__name">
					<p>{roomName(room._id)}</p>
					<p>{inCurrentCurrency(item.total)}</p>
				</div>
				<div className="item__description__options">
					<div className="item__description__options__option dates">
						<ion-icon name="calendar"></ion-icon>
						<p>
							{visibleDate(item.arrivalDate)}
							{' - '}
							{visibleDate(item.departureDate)}
						</p>
					</div>
					<div className="item__description__options__option persons">
						<ion-icon name="person"></ion-icon>
						<p>
							<Translate string="rates.occupancy.adults" />{': '}
							{item.adults}
							{' - '}
							<Translate string="rates.occupancy.children" />{': '}
							{item.children}
						</p>
					</div>
					<div className="item__description__options__option rates">
						<ion-icon name="cash"></ion-icon>
						<p>
							<Translate string="rates.rate" />{': BAR Direct'}
						</p>
					</div>
				</div>
				<div className="item__description__description">
					<p 
						className={`show-more${includedOpen?' open':''}`}
						onClick={() => setIncludedOpen(!includedOpen)}
					>
						<Translate string="rates.whatsIncluded" />
						<ion-icon name="chevron-down-outline"></ion-icon>
					</p>
					<p 
						className={`included${includedOpen?' open':''}`}
						dangerouslySetInnerHTML={{__html: includedOpen?included[n]:''}}
					></p>
				</div>
			</div>
		</div>
	)
}

export default Item
