import { useState, useEffect } from "react";
import "./styles/Banner.scss";
import bannerVideo from "./../images/palais.mp4";
import BookingForm from "./BookingForm";
import ScrollButton from "./ScrollButton";

function Banner() {
  const [error, setError] = useState(false);
  const picsNum = 6;
  let currentPic = Math.floor(Math.random() * picsNum + 1);
  const nextPic = () => {
    if (currentPic === picsNum) {
      currentPic = 1;
    } else {
      currentPic++;
    }
    showImage(currentPic);
  };
  const showImage = (n) => {
    if (n > picsNum) {
      console.log("Warning: the image called for does not exist");
    }
    for (let i = 1; i <= picsNum; i++) {
      let imageElement = document.querySelector(`.pic.p-${i}`);
      if (!imageElement) return;
      if (i === n) {
        imageElement.classList.add("current");
      } else {
        imageElement.classList.remove("current");
      }
    }
  };
  const showVideo = () => {
    setTimeout(() => {
      document.querySelector(".loading").classList.remove("hidden");
      setTimeout(() => {
        document.querySelector(".loading").classList.add("hidden");
        for (let i = 1; i <= picsNum; i++) {
          let imageElement = document.querySelector(`.pic.p-${i}`);
          imageElement.classList.add("video-initialized");
        }
        document.querySelector("#bg-video").classList.add("start");
        document.querySelector("#bg-video").play();
      }, 4000);
    }, 6000);
  };
  useEffect(() => {
    setInterval(() => {
      nextPic();
    }, 4000);
  }, []);
  return (
    <div className="banner">
      <div className={`modal${error ? " show" : ""}`}>
        <div className="content">
          <p>
            Please make sure the arrival and departure dates are valid and try
            again.
          </p>
          <button className="gold primary" onClick={() => setError(false)}>
            Try again
          </button>
        </div>
      </div>
      <div className="content">
        <BookingForm error={error} setError={setError} />
        <ScrollButton />
      </div>
      <div className="banner-video">
        <div className="loading hidden"></div>
        <video muted loop id="bg-video" onLoadedData={showVideo}>
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
      <div className="header-effect"></div>
      <div className="pic-container">
        {[...Array(picsNum)].map((pic, i) => {
          i++;
          return (
            <div
              className={`pic p-${i} ${i === currentPic ? "current" : ""}`}
              style={{
                backgroundImage:
                  "url('./new/pic-" + i + ".jp" + (i === 1 ? "" : "e") + "g')",
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Banner;
