export const rooms = [
  {
    _id: "6132c751e0997d66370c9a1b",
    id: 1,
    maxP: 4,
    price: 60,
    description:
      "Experience the charm of Fez Medina in our Comfort Triple Room. Featuring a queen bed and one twin bed, this space blends traditional Moroccan decor with modern comforts. Enjoy authentic ambiance and medina views in this cozy retreat.",
    name: "Comfort Triple Room",
  },
  {
    _id: "6132c781e0997d66370c9a1c",
    id: 2,
    maxP: 4,
    price: 60,
    description:
      "Discover comfort in the heart of Fez Medina. This charming room offers a queen bed and one twin bed, blending traditional Moroccan style with modern amenities. Enjoy a cozy stay with authentic local flair.",
    name: "Comfort Triple Room",
  },
  {
    _id: "6132c79de0997d66370c9a1d",
    id: 3,
    maxP: 5,
    price: 75,
    description:
      "Immerse yourself in Fez Medina's charm in our spacious Comfort Quadruple Room. Featuring a queen bed and two twin beds, this room blends traditional Moroccan style with modern comforts, offering a cozy retreat for families or groups.",
    name: "Comfort Quadruple Room",
  },
  {
    _id: "6132c7b5e0997d66370c9a1e",
    id: 4,
    maxP: 4,
    price: 60,
    description:
      "Cultural room with a queen bed and two twin beds. Features hand-crafted Moroccan decor, mountain views, and modern amenities. Experience Fez's rich culture with comfort and stunning vistas.",
    name: "Comfort Triple Room with Mountain View",
  },
  {
    _id: "6132c84be0997d66370c9a20",
    id: 5,
    maxP: 3,
    price: 45,
    description:
      "Elegant double room in the heart of Fez Medina. Features a comfortable queen-sized bed and traditional Moroccan decor, offering a serene retreat after exploring the vibrant city.",
    name: "Double Room with Mountain View",
  },
  {
    _id: "6132c899e0997d66370c9a21",
    id: 6,
    maxP: 3,
    price: 45,
    description:
      "Charming double room nestled in Fez Medina. Boasts a luxurious queen-sized bed and tasteful Moroccan touches, providing a serene haven in the bustling old city.",
    name: "Double Room with Mountain View",
  },
  {
    _id: "6132c8bce0997d66370c9a22",
    id: 7,
    maxP: 3,
    price: 45,
    description:
      "Serene double room in Fez Medina. Luxurious queen bed, traditional decor, and breathtaking mountain views create a peaceful retreat.",
    name: "Double Room with Mountain View",
  },
];
