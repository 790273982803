import './styles/Loading.scss'
import Spinner from './../images/Spinner.gif'
import {useEffect} from 'react'

function Loading() {
    var duration = 2000
    useEffect(() => {
        const loadingElement = document.querySelector('.Loading')
        setTimeout(() => {
            loadingElement.classList.add('hide')
            setTimeout(() => {
                loadingElement.parentElement.removeChild(loadingElement)
            }, 600)
        }, duration)
    }, [])
    return (
        <div className="Loading">
           <img src={Spinner} alt="Loading spinner..." height="100" />
           <p>Loading booking client...</p>
        </div>
    )
}

export default Loading
