import React from "react";
import "./styles/About.scss";

function About() {
  return (
    <div className="About" id="about" data-aos="fade-up">
      <div className="top">
        <p className="title smoll">Welcome to Palais Shazam,</p>
        <p className="title big">
          A LUXURY HOTEL IN THE HEART OF FES MEDINA
          <span></span>
        </p>
      </div>
      <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
        <p>
          Welcome to a Timeless Oasis in the Heart of Fez. Experience the allure
          of the Old Medina as you step into Palais Shazam. Nestled amidst the
          bustling streets, Palais Shazam offers an exquisite blend of Moroccan
          tradition and modern comforts. From the moment you arrive, you'll be
          transported to a bygone era, where the rich history and vibrant
          culture of Fez come alive.
        </p>
        <p>
          Immerse yourself in the enchanting ambiance, as the captivating
          architecture, intricate tilework, and ornate details create an
          atmosphere of unparalleled beauty. Palais Shazam is more than just a
          place to stay; it's a haven where time slows down, allowing you to
          unwind and rejuvenate. Discover the magic of the old Fez medina, where
          history, indulgence, and warm Moroccan hospitality converge.
        </p>
        <button
          className="gold primary"
          onClick={() => window.location.replace("/book")}
        >
          Book Now
        </button>
      </div>
    </div>
  );
}

export default About;
