import './styles/BookingContent.scss'
import BookingDates from './BookingDates'
import BookingRooms from './BookingRooms'
import BookingRates from './BookingRates'
import BookingDetails from './BookingDetails'
import BookingSummary from './BookingSummary'
import { useState } from 'react'

function BookingContent(props) {
    const {
        shownStep, setShownStep, 
        currentStep, setCurrentStep,
        arrivalDate, departureDate,
        setArrivalDate, setDepartureDate,
        currentLanguage, currentCurrency,
        currentItems, setCurrentItems,
        currentRoom, setCurrentRoom,
        inCurrentCurrency, visibleDate,
        setClient, inCheckOut, setInCheckOut
    } = props
    const [rooms, setRooms] = useState([])
    const nextStep = () => {
        if (!(currentStep === 5)) {
            if (currentStep === shownStep) {
                setCurrentStep(currentStep+1);
                setShownStep(currentStep+1);
            } else {
                setShownStep(shownStep+1)
            }
        } else {
            if (!(shownStep===5)) {
                setShownStep(shownStep+1)
            }
        }
    }
   

    const included = [
        `
            This rate includes :
            <ul>
                <li>Accommodation</li>
                <li>Breakfast</li>
                <li>Swimming pool access during stay</li>
                <li>The local city tax</li>
            </ul>
            
            Booking Terms :
            <ul>
                <li>50% of the total amount at the time of booking</li>
                <li>The rest to be paid during check-in</li>
                <li>All rates are NON-REFUNDABLE</li>
            </ul>

            Cancellation terms :
            <ul>
                <li>Cancellation is NOT free of charge under any circumstances</li>
                <li>Please contact the reservation service in the case of cancellation of your reservation</li>
            </ul>

            For any more information, please feel free to contact us.
        `,
        `
            Ce tarif comprend :
            <ul>
                <li>Hébergement</li>
                <li>Petit-Déjeuner</li>
                <li>Accès piscine pendant le séjour</li>
                <li>La taxe de séjour locale</li>
            </ul>
        
            Conditions de réservation :
                <ul>
                    <li>50% du montant total au moment de la réservation</li>
                    <li>Le reste à payer lors du check-in</li>
                    <li>Tous les tarifs sont NON REMBOURSABLES</li>
                </ul>
                
                Conditions d'annulation :
                <ul>
                    <li>L'annulation n'est en aucun cas gratuite</li>
                    <li>Merci de contacter le service réservation en cas d'annulation de votre réservation</li>
                </ul>
                
                Pour plus d'informations, n'hésitez pas à nous contacter.
                `,
                `
                Esta tarifa incluye :
                <ul>
                    <li>Habitación</li>
                    <li>Desayuno</li>
                    <li>Acceso a la piscina durante la estancia</li>
                    <li>Tasa turística local</li>
                </ul>
                
                Condiciones de reserva :
                <ul>
                <li>50% del importe total en el momento de la reserva</li>
                <li>El resto a pagar al momento del check-in</li>
                <li>Todas las tarifas NO SON REEMBOLSABLES</li>
                </ul>
                
                Conditions d'annulation :
                <ul>
                <li>La cancelación no es gratuita</li>
                <li>Póngase en contacto con el departamento de reservas en caso de cancelación de su reserva.</li>
                </ul>
                
                Para obtener más información, no dude en contactarnos. 
                `,
                `
                    Questa tariffa include  :
                    <ul>
                        <li>Struttura ricettiva</li>
                        <li>Colazione</li>
                        <li>Accesso alla piscina durante il soggiorno</li>
                        <li>La tassa comunale di soggiorno</li>
                    </ul>
                    
                    Termini di prenotazione:
                    <ul>
                        <li>50% dell'importo totale al momento della prenotazione</li>
                        <li>Il resto da pagare al momento del check-in</li>
                        <li>Tutte le tariffe NON SONO RIMBORSABILI</li>
                    </ul>
        
                    Termini di cancellazione:
                    <ul>
                        <li>La cancellazione NON è gratuita in nessun caso</li>
                        <li>Si prega di contattare il servizio di prenotazione in caso di cancellazione della prenotazione</li>
                    </ul>
        
                    Per ulteriori informazioni, non esitate a contattarci.
                `,
                `
                    Dieser Preis beinhaltet:
                    <ul>
                        <li>Unterkunft</li>
                        <li>Frühstück</li>
                        <li>Zugang zum Pool während des Aufenthalts</li>
                        <li>Die örtliche Kurtaxe</li>
                    </ul>
                    
                    Buchungsbedingungen:
                    <ul>
                        <li>50% des Gesamtbetrages zum Zeitpunkt der Buchung</li>
                        <li>Der Rest ist beim Check-in zu bezahlen</li>
                        <li>Alle Preise sind NICHT ERSTATTUNGSFÄHIG</li>
                    </ul>
        
                    Stornierungsbedingungen:
                    <ul>
                        <li>Die Stornierung ist unter keinen Umständen kostenlos</li>
                        <li>Bitte kontaktieren Sie den Reservierungsservice im Falle einer Stornierung Ihrer Reservierung</li>
                    </ul>
        
                    Für weitere Informationen können Sie uns gerne kontaktieren. 
                `
            ]
            
            
            const nObj = {'en':0,'fr':1,'es':2,'it':3,'de':4}
            const n = nObj[currentLanguage]
            
            return (
                <div className="main-content">
            <BookingDates 
                currentStep={currentStep}
                shownStep={shownStep} nextStep={nextStep} 
                arrivalDate={arrivalDate} departureDate={departureDate}
                setArrivalDate={setArrivalDate} setDepartureDate={setDepartureDate}
                setCurrentStep={setCurrentStep}
                currentLanguage={currentLanguage} currentCurrency={currentCurrency}
                setRooms={setRooms}
            />
            <BookingRooms 
                currentStep={currentStep}
                setCurrentStep={setCurrentStep} setShownStep={setShownStep}
                shownStep={shownStep} nextStep={nextStep}
                arrivalDate={arrivalDate} departureDate={departureDate}
                setRooms={setRooms}
                rooms={rooms} currentLanguage={currentLanguage}
                inCurrentCurrency={inCurrentCurrency} currentItems={currentItems}
                setCurrentRoom={setCurrentRoom} visibleDate={visibleDate}
            />
            <BookingRates 
                currentStep={currentStep}
                shownStep={shownStep} nextStep={nextStep} 
                currentRoom={currentRoom} currentItems={currentItems}
                setCurrentItems={setCurrentItems} inCurrentCurrency={inCurrentCurrency}
                arrivalDate={arrivalDate} departureDate={departureDate} visibleDate={visibleDate}
                setShownStep={setShownStep} currentLanguage={currentLanguage}
                included={included}
            />
            <BookingSummary 
                currentStep={currentStep}
                shownStep={shownStep} nextStep={nextStep} setShownStep={setShownStep}
                currentItems={currentItems} inCurrentCurrency={inCurrentCurrency}
                included={included} n={n}
            />
            <BookingDetails 
                currentStep={currentStep}
                shownStep={shownStep} nextStep={nextStep} 
                rooms={rooms} currentItems={currentItems}
                inCurrentCurrency={inCurrentCurrency} setInCheckOut={setInCheckOut}
                setClient={setClient} currentCurrency={currentCurrency} currentLanguage={currentLanguage}
            />
        </div>
    )
}

export default BookingContent
