import { useState } from 'react'
import Translate from './Translate'

function Product(props) {
	const { 
		product, inCurrentCurrency, setRatesLoading,
		includedProducts, setIncludedProducts 
		} = props
	const [ includedOpen, setIncludedOpen ] = useState(false)
	const [ added, setAdded ] = useState(false)
	return (
		<div className="product">
			<div className="product__picture">
				<img src={product.picture} alt={product.name} />
			</div>
			<div className="product__description">
				<h3>{product.title}</h3>
				<p className={`included${includedOpen?' open':''}`}>
					{product.description}
				</p>
				<p 
					className={`show-more${includedOpen?' open':''}`}
					onClick={() => setIncludedOpen(!includedOpen)}
				>
					<Translate string="rates.whatsIncluded" />
					<ion-icon name="chevron-down-outline"></ion-icon>
				</p>
				<div className="product__footer">
					<div className="price">
						<p className="main-price">
							{inCurrentCurrency(product.price)}
						</p>
						<p className="small-text">
							<Translate string={`rates.products.${product.quantity}`} />
						</p>
					</div>
					<button 
						className={added?'disabled-btn with-icon':'secondary'}
						onClick={() => {
							setRatesLoading(true)
							let temp = includedProducts
							if (!added) { // add product
								temp.push(product.id)
							} else { // remove product
								temp = temp.filter(element => element!==product.id)
							}
							setIncludedProducts(temp)
							setAdded(!added)
						}}
					>
						{added &&
							<ion-icon name="checkmark"></ion-icon>
						}
						{added?
							<Translate string="rates.products.added" /> :
							<Translate string="rates.products.add" />
						}
					</button>
				</div>
			</div>
		</div>
	)
}

export default Product
