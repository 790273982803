import React, { PureComponent } from 'react'
import {LocaleContext} from './../locale-context'
import en from './../langs/en.json'
import fr from './../langs/fr.json'
import it from './../langs/it.json'
import de from './../langs/de.json'
import es from './../langs/es.json'


export default class Translate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            langs: {
                en, fr, it, de, es
            },
        }
    }

    render() {
        const { langs } = this.state
        const { string } = this.props
        return (
                <LocaleContext.Consumer>
                    {(value) => langs[value][string]}
                </LocaleContext.Consumer>
        )
    }
}
