import "./styles/BookingRooms.scss";
import Translate from "./Translate";
import RoomCard from "./RoomCard";
import { useState, useEffect } from "react";
import spinner from "./../images/spinnerDarkBG.gif";
import Cookies from "js-cookie";
import { rooms } from "../../data/rooms";

function BookingRooms(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    currentStep,
    setCurrentStep,
    setShownStep,
    shownStep,
    nextStep,
    arrivalDate,
    departureDate,
    currentLanguage,
    setCurrentRoom,
    inCurrentCurrency,
    visibleDate,
  } = props;
  const isDate = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };
  useEffect(() => {
    if (shownStep === 2) {
      /* 
		This is supposed to only work when the user
		visits the rooms tab. The useEffect checks
		everytime 'shownStep' is reset, and if it's
		equal to 2 (rooms tab) it will execute this.
		*/
      setLoading(true);
      setError(null);
      let arrival, departure;
      let savedArrival = Cookies.get("arr");
      let savedDeparture = Cookies.get("dep");
      if (isDate(savedArrival) && isDate(savedDeparture)) {
        arrival = savedArrival;
        departure = savedDeparture;

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setCurrentStep(1);
        setShownStep(1);
      }
    }
  }, [shownStep]);
  if (currentStep >= 2) {
    if (loading || error) {
      return (
        <div
          className={`container rooms full-screen${
            shownStep === 2 ? " slide-in" : ""
          }${shownStep > 2 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="rooms.title" />
          </p>
          <p className="sub-title">
            <button className="disabled-btn" onClick={() => setShownStep(1)}>
              <ion-icon name="calendar-outline"></ion-icon>
              {visibleDate(arrivalDate)} &rarr; {visibleDate(departureDate)}
            </button>
          </p>
          <p className="error-loading-msg">
            {loading && <img height="80" src={spinner} alt="Loading..." />}
            {error && <Translate string={error} />}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={`container rooms full-screen${
            shownStep === 2 ? " slide-in" : ""
          }${shownStep > 2 ? " passed" : ""}`}
        >
          <p className="title">
            <Translate string="rooms.title" />
          </p>
          <p className="sub-title">
            <button className="disabled-btn" onClick={() => setShownStep(1)}>
              <ion-icon name="calendar-outline"></ion-icon>
              {visibleDate(arrivalDate)} &rarr; {visibleDate(departureDate)}
            </button>
          </p>
          {rooms.length !== 0 && ( // there are rooms available
            <div className="rooms-grid-wrapper">
              {rooms.map((room) => (
                <RoomCard
                  key={room.id}
                  nextStep={nextStep}
                  roomData={room}
                  currentLanguage={currentLanguage}
                  setCurrentRoom={setCurrentRoom}
                  inCurrentCurrency={inCurrentCurrency}
                  arrivalDate={visibleDate(arrivalDate)}
                  departureDate={visibleDate(departureDate)}
                />
              ))}
            </div>
          )}
          {rooms.length === 0 && ( // there are no available rooms
            <div className="rooms-not-found">
              <p className="apologies">
                <Translate string="rooms.notFound.message" />
              </p>
              <p className="suggesst">
                <Translate string="rooms.notFound.suggestion" />
              </p>
              <button
                className="disabled-btn"
                onClick={() => {
                  setShownStep(1);
                }}
              >
                <span>&larr;</span>
                <Translate string="rooms.notFound.changeDates" />
              </button>
              <p>
                <Translate string="rooms.notFound.contactUs" />
              </p>
              <div className="phone">
                <ion-icon name="call"></ion-icon>
                <a href="tel:+212661519014">00 212 6 61 51 90 14</a>
              </div>
              <div className="email">
                <ion-icon name="mail"></ion-icon>
                <a href="mailto:palaisshazam@gmail.com">
                  palaisshazam@gmail.com
                </a>
              </div>
            </div>
          )}
        </div>
      );
    }
  } else {
    return (
      <div
        className={`container${shownStep === 2 ? " slide-in" : ""}${
          shownStep > 2 ? " passed" : ""
        }`}
      >
        <p>
          <Translate string="booking.container.error" />
        </p>
      </div>
    );
  }
}

export default BookingRooms;
