import './styles/BookingDetails.scss'
import loadingSpinner from './../images/loadingSpinner.gif'
import Translate from './Translate'
import { useState, useMemo } from 'react'
import countryList from 'react-select-country-list'

function BookingDetails(props) {
    const {
        currentStep, shownStep, nextStep,
        currentItems, inCurrentCurrency, rooms,
        setInCheckOut, setClient, currentCurrency, currentLanguage
    } = props


    const allNationalities = useMemo(() => countryList().getData(), [])


    const [ payNowLoading, setPayNowLoading ] = useState(false)


    //form states
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ nationality, setNationality ] = useState('')
    const [ specialRequests, setSpecialRequests ] = useState('')
    const [ agree, setAgree ] = useState(false)
    const [ getUpdates, setGetUpdates ] = useState(true)
    

    // form states EERRORRs (for required ones)
    const [ lastNameError, setLastNameError ] = useState('')
    const [ emailError, setEmailError ] = useState('')
    const [ phoneError, setPhoneError ] = useState('')
    const [ nationalityError, setNationalityError ] = useState('')
    const [ agreeError, setAgreeError ] = useState(false)


    const [ formError, setFormError ] = useState('')


    const inputs = [
        {
            title: <Translate string="details.form.firstName" />,
            required: false,
            value: firstName,
            setValue: setFirstName,
            onChange(e) {
                this.setValue(e.target.value)
            }
        },
        {
            title: <Translate string="details.form.lastName" />,
            required: true,
            value: lastName,
            setValue: setLastName,
            error: lastNameError,
            setError: setLastNameError,
            onChange(e) {
                this.setValue(e.target.value)
                this.setError('')
                setFormError('')
            }
        },
        {
            title: <Translate string="details.form.email" />,
            required: true,
            isEmail: true,
            value: email,
            setValue: setEmail,
            error: emailError,
            setError: setEmailError,
            onChange(e) {
                this.setValue(e.target.value)
                this.setError('')
                setFormError('')
            }
        },
        {
            title: <Translate string="details.form.phone" />,
            required: true,
            isPhone: true, // this is bad practice but im too lazy to make it into a better practice, so just read the rest of the code and stfu, even though i don't think no one ever will read this. If you're reading this though, get a fucking life.
            value: phone,
            setValue: setPhone,
            error: phoneError,
            setError: setPhoneError,
            onChange(e) {
                this.setValue(e.target.value)
                this.setError('')
                setFormError('')
            }
        },
        {
            title: <Translate string="details.form.nationality" />,
            required: true,
            isNationality: true,
            value: nationality,
            setValue: setNationality,
            error: nationalityError,
            setError: setNationalityError,
            onChange(e) {
                this.setValue(e.target.value)
                this.setError('')
                setFormError('')
            }
        }
    ]
    
    
    const roomName = (id) => {
        if (rooms.filter(room => room._id === id)[0]) {
            return rooms.filter(room => room._id === id)[0].name
        } else {
            return 'Room'
        }
    }

    let total = 0
    currentItems.map(item => {
        total += item.total
    })



    const inputsAreValid = () => {
        // insane form input validation 
        let valid = true
        let mainError = <Translate string="details.form.error.main" />
        let requiredError = <Translate string="details.form.error.required" />
        let emailErrorMsg = <Translate string="details.form.error.email" />
        let phoneErrorMsg = <Translate string="details.form.error.phone" />
        inputs.map(input => {
            let inputValue = input.value.trim()
            if (input.required && inputValue.trim() === '') {
                input.setError(requiredError)
                setFormError(mainError)
                valid = false
            }
            if (valid && input.isEmail) {
                // im in a really bad mood,
                // yes, i wanna do email
                // input validation myself
                // go fuck yourself.
                if (input.value.trim().split('').includes(' ')) {
                    input.setError(emailErrorMsg)
                    setFormError(mainError)
                    valid = false
                } else {
                    let emailName = inputValue.split('@')[0].trim()
                    let emailDomain = inputValue.split('@')[1].trim()
                    if (emailName && emailDomain) {
                        let emailDomainName = emailDomain.split('.')[0].trim()
                        let emailDomainExt = emailDomain.split('.')[1].trim()
                        if (
                            !emailDomainName || !emailDomainExt ||
                            emailName.length < 2 || emailDomainName.length < 2 || 
                            emailDomainExt.length < 2
                            ) {
                            input.setError(emailErrorMsg)
                            setFormError(mainError)
                            valid = false
                        }
                    } else {
                        input.setError(emailErrorMsg)
                        setFormError(mainError)
                        valid = false
                    }
                }
            }
            if (valid && input.isPhone) {
                if (inputValue < 8) {
                    input.setError(phoneErrorMsg)
                    setFormError(mainError)
                    valid = false
                }
            }
        })


        return valid
    }

    const payNow = async () => {
        setPayNowLoading(true)
        if (inputsAreValid()) {
            if (agree) {
                let detectBrowser = () => {
                    switch (true) {
                        case (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0:
                            return 'Opera'
                            break
                        case typeof InstallTrigger !== 'undefined':
                            return 'Firefox'
                            break
                        case /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)):
                            return 'Safari'
                            break
                        case /*@cc_on!@*/false || !!document.documentMode:
                            return 'Internet Explorer'
                            break
                        case !!window.StyleMedia:
                            return 'Edge'
                            break
                        case !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && (navigator.userAgent.indexOf("Edg") != -1):
                            return 'Edge Chromium'
                            break
                        case !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime):
                            return 'Chrome'
                            break
                        default:
                            return 'Unknown'
                    }
                }
                let getIpAddress = async () => {
                    let response = await fetch('https://json.geoiplookup.io')
                    let responseJson = await response.json()
                    let ipAddress = await responseJson.ip
                    return ipAddress
                }
                let clientIpAddress = await getIpAddress()
                let currentClient = {
                    name: `${firstName?firstName.trim()+' ':''}${lastName.trim()}`,
                    email: email.trim(),
                    phone: phone.trim(),
                    nationality: nationality,
                    specialRequests: specialRequests.trim()===''?'none.':specialRequests.trim(),
                    getUpdates: getUpdates,
                    timeAdded: new Date(),
                    currencyUsed: currentCurrency,
                    languageUsed: currentLanguage,
                    browserUsed: detectBrowser(),
                    ipAddress: clientIpAddress
                }
                setClient(currentClient)
                setTimeout(() => {
                    setInCheckOut(true)
                    setPayNowLoading(false)
                }, 800)
            } else {
                setAgreeError(true)
                setPayNowLoading(false)
            }
        } else {
            setPayNowLoading(false)
        }
    }
    if (currentStep >=5) {
        return (
            <div 
            className={`container details full-screen${shownStep===5?' slide-in':''}${shownStep>5?' passed':''}`}
            >
                <p className="title">
                    <Translate string="booking.header.details" />
                </p>
                <div className="wrapper">
                    <div className="content-wrapper form">
                        {formError && 
                            <div className="error-note">
                                <p>{formError}</p>
                            </div>
                        }
                        <div className="grid-wrapper">
                            {inputs.map((input, i) => (
                                <div 
                                    key={i}
                                    className={`input-wrapper${input.error?' error':''}`}
                                >
                                    <label>
                                        {input.title} {input.required && ' *'}
                                    </label>
                                    {input.isNationality ? 
                                        (<select
                                            value={input.value}
                                            onChange={e => input.onChange(e)}
                                        >
                                            <option></option>
                                            {allNationalities.map((nationality, i) => (
                                                <option key={i}>
                                                    {nationality.label}
                                                </option>
                                            ))}
                                        </select>) :
                                        (<input 
                                            type={input.isEmail?'email':(input.isPhone?'tel':'text')}
                                            value={input.value}
                                            onChange={e => input.onChange(e)}
                                        />)
                                    }
                                    {input.error ?
                                        <p className="note error">
                                            {input.error}
                                        </p>:''
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="input-wrapper">
                            <label>
                                <Translate string="details.form.specialRequests" />
                            </label>
                            <textarea
                                value={specialRequests}
                                onChange={e => setSpecialRequests(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <p className="title">Payment</p>
                    <div className="content-wrapper payment">
                        <div className="total-card">
                            {currentItems.map((item, i) => (
                                <div className="item-price" key={i}>
                                    <p className="item-name">
                                        {roomName(item.roomID)}
                                    </p>
                                    <p className="item-total">
                                        {inCurrentCurrency(item.total)}
                                    </p>
                                </div>
                            ))}
                            <div className="total-price">
                                <p className="total-name">Total</p>
                                <p className="total-total">
                                    {inCurrentCurrency(total)}
                                </p>
                            </div>
                        </div>
                        <p className="pay-note">
                            <Translate string="details.payment.note1" />
                            <br />
                            <Translate string="details.payment.note2" />
                        </p>
                        <div className="checkboxes">
                            {agreeError && 
                                <div className="error-note">
                                    <p>
                                        <Translate string="details.payment.terms.error" />
                                    </p>
                                </div>
                            }
                            <div className="checkbox">
                                <input 
                                    type="checkbox"
                                    checked={agree}
                                    onChange={e => {setAgreeError('');setAgree(e.target.checked)}}
                                />
                                <p>
                                    <Translate string="details.payment.terms1" />
                                    <a href="#">
                                        <Translate string="details.payment.terms2" />
                                    </a>
                                </p>
                            </div>
                            <div className="checkbox">
                                <input 
                                    type="checkbox"
                                    checked={getUpdates}
                                    onChange={e => {setGetUpdates(e.target.checked)}}
                                />
                                <p>
                                    <Translate string="details.payment.updates" />
                                </p>
                            </div>
                        </div>
                        <div className="button-wrapper">
                            <button
                                className="booking-btn gold next-btn"
                                onClick={payNow}
                                disabled={payNowLoading}
                            >
                                {payNowLoading ?
                                    <img src={loadingSpinner} alt="Loading" height={15} /> :
                                    <Translate string="details.payment.payNow" />
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`container${shownStep===5?' slide-in':''}${shownStep>5?' passed':''}`}>
                <p>An error occurred. This should not have happened... Please refresh the page and try again.</p>
            </div>
        )
    }
}

export default BookingDetails
