import React, { useState, Suspense } from "react";
import "./components/styles/main.scss";
import FixedHeader from "./components/FixedHeader";
import MainHeader from "./components/MainHeader";
import MobileMenu from "./components/MobileMenu";
import BGEffect from "./components/BGEffect";
import Banner from "./components/Banner";
import About from "./components/About";
import Rooms from "./components/Rooms";
import Restaurant from "./components/Restaurant";
import Pool from "./components/Pool";
import Events from "./components/Events";
import UnderMap from "./components/UnderMap";
import Instagram from "./components/Instagram";
import Footer from "./components/Footer";

const Map = React.lazy(() => import("./components/Map"));

function Home() {
  const [menuStatus, setMenuStatus] = useState(false);
  const toggleMenu = () => {
    setMenuStatus(!menuStatus);
  };
  return (
    <div className="Home">
      <BGEffect menuStatus={menuStatus} toggleMenu={toggleMenu} />
      <MobileMenu menuStatus={menuStatus} toggleMenu={toggleMenu} />
      <FixedHeader menuStatus={menuStatus} toggleMenu={toggleMenu} />
      <MainHeader menuStatus={menuStatus} toggleMenu={toggleMenu} />
      <Banner />
      <div id="scroll"></div>
      <About />
      <Rooms />
      <Restaurant />
      <Pool />
      <Events />
      <Suspense fallback={<div>Loading...</div>}>
        <Map
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA8lYR_a0ew20JmZ1NWGxhlARUsL9weEBo`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              className="map-element"
              id="map"
              data-aos="fade-up"
              data-aos-duration="800"
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Suspense>
      <UnderMap />
      <Instagram />
      <Footer />
    </div>
  );
}
// Lets go

export default Home;
